<template>
  <div class="Eurbom_Archive">
    <div class="Header">
      <a>Archive</a>
      <div class="Search_Box">
        <input type="text" v-model="search" placeholder="Search..." />
        <img src="../../assets/SearchIcon.png" />
      </div>
    </div>
    <div class="toggle_title">Preferential filter</div>
    <div class="toggle_main_container">
      <div class="toggle_container">
        <button
          class="toggle_button"
          :class="{ active: selected_preferential == true }"
          @click="toggle_preferential(true)"
        >
          Yes
        </button>
        <button
          class="toggle_button"
          :class="{ active: selected_preferential == false }"
          @click="toggle_preferential(false)"
        >
          No
        </button>
      </div>
      <div class="clear_button_container" v-if="selected_preferential != null">
        <button class="clear_button" @click="clear_preferential">Clear</button>
      </div>
    </div>
    <div class="Table_Header">
      <p>Part Number</p>
      <p>FCA product price €</p>
      <p>Factory</p>
      <p>Total Sum €</p>
      <p>Total Pref Sum €</p>
      <p>Total Sum - Total Pref Sum €</p>
      <p>% Material - FCA price</p>
      <p>% Pref Material - FCA price</p>
      <p>% Material - % Pref Material</p>
      <p>Preferential</p>
      <p>Invalid price</p>
      <p>EUR StS Year</p>
      <p>Date</p>
      <p></p>
    </div>
    <div
      style="overflow: auto; width: 100%; display: flex; flex-direction: column"
    >
      <div
        class="Table-Content"
        v-if="!loading && data.length > 0"
        v-for="item in data"
      >
        <div>
          <p>{{ item.name }}</p>
          <p>{{ item.fca }} €</p>
          <p>{{ item.factory }}</p>
          <p>{{ item.totalSumEur }} €</p>
          <p>{{ item.prefSumEur }} €</p>
          <p>{{ (item.totalSumEur - item.prefSumEur).toFixed(2) }} €</p>
          <p>{{ item.fcaPercent }} %</p>
          <p>{{ item.prefFcaPercent }} %</p>
          <p>{{ (item.fcaPercent - item.prefFcaPercent).toFixed(2) }} %</p>
          <p
            :style="`color: ${item.eur1Status ? 'green' : 'red'} !important;`"
            style="font-weight: 600"
          >
            {{ item.eur1Status ? "Yes" : "No" }}
          </p>
          <p
            :style="`color: ${item.invalidPrice ? 'red' : 'green'} !important;`"
            style="font-weight: 600"
          >
            {{ item.invalidPrice ? "Yes" : "No" }}
          </p>
          <p>{{ `20${item.year}` }}</p>
          <p>{{ this.formatDate(item.date) }}</p>
          <p>
            <img
              src="../../assets/ViewMore.png"
              title="View details"
              style="margin-left: 2.5px; margin-right: 2.5px"
              @click="show_details(item)"
            />
            <img
              src="../../assets/Download.png"
              title="Download"
              style="margin-left: 2.5px; margin-right: 2.5px"
              @click="download(item)"
            />
          </p>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
          "
          v-if="selected_calculation_details == item.id"
        >
          <Calculation_Details
            v-if="selected_calculation_details == item.id"
            :data="calculation_details"
            ref="calculationDetails"
          />
        </div>
      </div>
    </div>

    <Loading_Row v-if="loading" />
    <Loading_Row v-if="loading" />
    <Loading_Row v-if="loading" />

    <p class="Nothing-To-Show" v-if="!loading && data.length == 0">
      Nothing to show
    </p>
    <div class="Bottom-Navigator" v-if="data.length > 0">
      <p
        v-show="
          this.pagination.currentPage != 1 && this.pagination.currentPage > 0
        "
        @click="
          this.get_all_calculations(
            parseInt(this.pagination.currentPage) - 1,
            selected_preferential
          )
        "
      >
        &#8592;
      </p>
      <p>Pages</p>
      <input
        type="number"
        v-model="pagination.currentPage"
        v-on:keyup.enter="
          this.get_all_calculations(
            parseInt(this.pagination.currentPage),
            selected_preferential
          )
        "
      />
      <p>of {{ this.pagination.totalPages }}</p>
      <p
        v-show="this.pagination.currentPage < this.pagination.totalPages"
        @click="
          this.get_all_calculations(
            parseInt(this.pagination.currentPage) + 1,
            selected_preferential
          )
        "
      >
        &#8594;
      </p>
    </div>
  </div>
</template>

<script>
import Get_All_Calculations from "../../api/Eurbom/Get_All_Calculations";
import { notify } from "@kyvg/vue3-notification";
import Calculation_Details from "./Archived_Calculation_Details.vue";
import Download_Report from "../../api/Eurbom/Download_Report";
import Loading_Row from "../CommonComponents/Loading_Row.vue";

export default {
  name: "Eurbom_Archive",
  data() {
    return {
      loading: false,
      token: "",
      calculation_details: null,
      selected_calculation_details: null,
      selected_preferential: null,
      data: [],
      pagination: {
        currentPage: 0,
        totalPages: 0,
      },
      search: "",
    };
  },

  components: {
    Calculation_Details,
    Loading_Row,
  },

  created() {
    this.token = localStorage.getItem("Token");
    this.get_all_calculations(1, this.selected_preferential);
  },

  watch: {
    search() {
      this.get_all_calculations(1, this.selected_preferential);
    },

    selected_preferential(newValue, oldValue) {
      if (newValue != oldValue) {
        this.get_all_calculations(1, newValue);
      }
    },
  },

  methods: {
    async get_all_calculations(page, preferential) {
      this.loading = true;
      let archive = true;
      Get_All_Calculations.Get_All_Calculations(
        this.search,
        archive,
        page,
        preferential,
        this.token
      )
        .then((res) => {
          this.data = res.data.data;
          this.pagination.currentPage = res.data.pagination.currentPage;
          this.pagination.totalPages = res.data.pagination.totalPages;
          this.loading = false;
        })
        .catch((err) => {
          notify({
            title: err,
            type: "Error",
          });
          this.loading = false;
        });
    },

    formatDate(isoString) {
      const date = new Date(isoString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },

    show_details(item) {
      if (this.selected_calculation_details == null) {
        this.calculation_details = item;
        this.selected_calculation_details = item.id;
        return;
      }
      if (this.calculation_details.id == item.id) {
        this.calculation_details = null;
        this.selected_calculation_details = null;
        return;
      }
      this.calculation_details = item;
      this.selected_calculation_details = item.id;
    },

    async download(item) {
      notify({
        title: "Download starting...",
        type: "Success",
      });
      await Download_Report.Download_Report(item.id, item.name, this.token);
    },

    toggle_preferential(boolean) {
      this.selected_preferential = boolean;
    },

    clear_preferential() {
      this.selected_preferential = null;
    },
  },
};
</script>

<style scoped>
.Eurbom_Archive {
  width: 100%;
  margin-top: 55px;
  height: calc(100vh - 55px);
  display: flex;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Header {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  justify-content: space-between;
}

.Header > a {
  font-family: Roboto;
  font-size: 15px;
  color: #0097b8;
  font-weight: bold;
  margin-bottom: -20px;
}

.Search_Box {
  width: 250px;
  height: 30px;
  display: flex;
  border-radius: 4px;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.Search_Box > input {
  outline: none;
  background-color: transparent;
  border: none;
  font-family: Roboto;
  font-size: 13px;
  color: #a7a7a7;
  width: 200px;
}

.Search_Box > img {
  height: 15px;
}

.Table_Header {
  background-color: #0097b8;
  width: calc(100% - 100px);
  margin-top: 30px;
  align-self: center;
  border-radius: 4px;
  height: fit-content;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 70px;
}

.Table_Header > p {
  color: #e7ebf1;
  font-weight: 500;
  font-family: Roboto;
  font-size: 14px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  width: 135px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.Table-Content {
  background-color: #f7f7f7;
  width: calc(100% - 100px);
  margin-top: 7px;
  margin-bottom: 7px;
  align-self: center;
  border-radius: 4px;
  height: fit-content;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(189, 189, 189, 1);
  box-shadow: 0px 0px 3px 0px rgba(189, 189, 189, 1);
}

.Table-Content:nth-child(1) {
  margin-top: 15px;
}

.Table-Content > div {
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 30px !important;
  overflow-x: auto;
}

.Table-Content > div > p {
  margin-left: 10px;
  margin-right: 10px;
  width: 135px;
  text-align: center;
  color: rgb(71, 71, 71);
  font-weight: 400;
  font-family: Roboto;
  font-size: 13px;
  margin-top: 7px;
  margin-bottom: 7px;
  word-wrap: break-word;
  margin-block-start: 0em;
  margin-block-end: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Table-Content > div > p > img {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin: auto;
}

.Table-Content > div > p > img:hover {
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 50%;
}

.Nothing-To-Show {
  font-size: 24px;
  align-self: center;
  font-family: Roboto;
  color: #d3cfcf;
  cursor: pointer;
}

.Bottom-Navigator {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.Bottom-Navigator > p {
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  margin-left: 4px;
  margin-right: 4px;
}

.Bottom-Navigator > input {
  width: 15px;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  text-align: center;
  border-bottom: 1px solid #8f8f8f;
  background-color: transparent;
}

.Bottom-Navigator > p:nth-child(1) {
  cursor: pointer;
}

.Bottom-Navigator > p:nth-child(5) {
  cursor: pointer;
}

.Bottom-Navigator > p:nth-child(1):hover {
  font-size: 14px;
}

.Bottom-Navigator > p:nth-child(5):hover {
  font-size: 14px;
}

.toggle_title {
  margin-left: 52px;
  margin-top: 49px;
  font-family: Roboto;
  font-size: 13px;
  color: #9b9b9b;
}

.toggle_main_container {
  margin-left: 52px;
  display: flex;
}

.toggle_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  margin-bottom: -20px;
  background-color: #f0f0f0;
  width: 140px;
  padding: 3px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(219, 219, 219, 0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(219, 219, 219, 0.75);
  box-shadow: 0px 0px 3px 0px rgba(219, 219, 219, 0.75);
}

.toggle_button {
  padding: 8px 12px;
  border: none;
  font-family: Roboto;
  font-size: 13px;
  border-radius: 15px;
  font-weight: 500;
  background-color: #f0f0f0;
  color: gray;
  cursor: pointer;
  width: 66px;
}

.toggle_button:hover {
  background-color: #fcf9f9;
}

.toggle_button.active {
  background-color: white;
  color: #0097b8;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(180, 180, 180, 0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(180, 180, 180, 0.75);
  box-shadow: 0px 0px 3px 0px rgba(180, 180, 180, 0.75);
}

.clear_button_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  margin-bottom: -20px;
  background-color: #f0f0f0;
  width: 70px;
  padding: 3px;
  border-radius: 15px;
  margin-left: 20px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(219, 219, 219, 0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(219, 219, 219, 0.75);
  box-shadow: 0px 0px 3px 0px rgba(219, 219, 219, 0.75);
}

.clear_button {
  padding: 8px 12px;
  border: none;
  font-family: Roboto;
  font-size: 13px;
  border-radius: 15px;
  font-weight: 500;
  background-color: #f0f0f0;
  color: rgb(231, 3, 3);
  cursor: pointer;
  width: 66px;
}

.clear_button:hover {
  background-color: #fcf9f9;
}

@media screen and (max-width: 1000px) {
  .Header {
    margin-left: 20px;
    margin-right: 20px;
  }

  .Table_Header {
    width: calc(100% - 40px);
  }

  .Table-Content {
    width: calc(100% - 40px);
  }

  .toggle_main_container {
    margin-left: 22px;
  }
  .toggle_title {
    margin-left: 22px;
  }
}

@media screen and (max-width: 680px) {
  .Search_Box {
    width: 180px;
  }

  .Search_Box > input {
    width: 130px;
  }
}

@media screen and (max-width: 680px) {
  .Search_Box {
    left: 50px;
  }
}
</style>
