<template>
  <div class="main_screen">
    <div class="component">
      <div class="img_container">
        <img src="../../assets/alert.png" />
      </div>
      <p>
        Are you sure you want to archive the calculation for <br />{{
          data.name
        }}
        ?
      </p>
      <span>Once archived, the calculation cannot be restored.</span>
      <div class="buttons_container">
        <button class="cancel_button" @click="cancel">Cancel</button>
        <button class="continue_button" v-show="!loading" @click="archive">
          Archive
        </button>
        <button class="continue_button_loading" v-show="loading">
          <img src="../../assets/LoadingButton.gif" alt="Loading" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";
import Archive_Calculation from "../../api/Eurbom/Archive_Calculation";

export default {
  name: "Archive_View",

  props: {
    data: Object,
    close: Function,
    load_again: Function,
  },

  data() {
    return {
      loading: false,
      token: null,
    };
  },

  created() {
    this.token = localStorage.getItem("Token");
  },

  methods: {
    cancel() {
      this.close();
    },

    async archive() {
      if (this.loading) return;
      this.loading = true;
      await Archive_Calculation.Archive_Calculation(this.data.id, this.token)
        .then((res) => {
          if (res.data.message == "Calculation successfully archived.") {
            notify({
              title: "Calculation sucessfully deleted !",
              type: "Success",
            });
            this.load_again();
          }
        })
        .catch((err) => {
          notify({
            title: err,
            type: "Error",
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.main_screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(37, 37, 37, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.component {
  width: 400px;
  height: fit-content;
  background-color: white;
  border-radius: 5px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.img_container {
  width: 35px;
  height: 35px;
  background-color: rgba(242, 243, 245, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 20px;
}

.img_container > img {
  margin-top: -3px;
  height: 22px;
  width: 22px;
}

.component > p {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 15px;
}

.component > span {
  font-family: Roboto;
  font-size: 13.5px;
  font-weight: 300;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  color: grey;
}

.component > .buttons_container {
  margin-top: 25px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel_button {
  border: 1px solid rgb(180, 180, 180);
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: white;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
  color: rgb(107, 107, 107);
  cursor: pointer;
}

.cancel_button:hover {
  background-color: rgb(238, 238, 238);
}

.cancel_button:active {
  background-color: rgb(230, 230, 230);
}

.continue_button {
  border: none;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: #0097b8;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  cursor: pointer;
}

.continue_button:hover {
  background-color: #0288a7;
}

.continue_button:active {
  background-color: #0097b8;
}

.continue_button_loading {
  border: none;
  padding: 2px 25px 2px 25px;
  border-radius: 5px;
  background-color: #0097b8;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  cursor: pointer;
}

.continue_button_loading > img {
  height: 20px;
  width: 20px;
}
</style>
