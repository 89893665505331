import axios from "axios";

const url = `https://myexcel.app:3000/eurBoom/archive`;

class Archive_Calculation {
  static Archive_Calculation(id, token) {
    return axios.put(`${url}/${id}`, {}, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}
export default Archive_Calculation;
