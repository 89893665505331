import axios from "axios";

const url = `https://myexcel.app:3000/eurBoom/deleteCalculation`;

class Delete_Calculation {
  static Delete_Calculation(id, token) {
    return axios.delete(`${url}/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  }
}
export default Delete_Calculation;
