import axios from "axios";

const url = `https://myexcel.app:3000/eurBoom/createEurBoomCalc`;

class Calculate {
  static Calculate(name, fca, factory, prefYear, token) {
    return axios.post(
      url,
      {
        name,
        fca,
        factory,
        prefYear,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  }
}
export default Calculate;
