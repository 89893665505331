<template>
  <div class="Calculate_Parrent">
    <div class="Calculate" v-click-outside="close">
      <div class="Header">
        <p class="Title">New calculation</p>
      </div>

      <div class="Container">
        <div class="Row">
          <input type="text" placeholder="Factory" disabled v-model="factory" />
          <select id="pref_year" v-model="pref_year">
            <option value="null" disabled selected>Preferential year</option>
            <option
              v-for="item in pref_years"
              :key="item.id"
              :value="item.value"
            >
              {{ item.year }}
            </option>
          </select>
        </div>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th>Part Number</th>
            <th>FCA €</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in rows" :key="row.id">
            <td
              contenteditable
              @paste="handlePaste($event, 'part_number')"
              @input="updateData(index, 'part_number', $event)"
              @click="set_selected_row(index)"
            >
              {{ row.part_number }}
            </td>
            <td
              contenteditable
              @paste="handlePaste($event, 'fca')"
              @input="updateData(index, 'fca', $event)"
              @click="set_selected_row(index)"
            >
              {{ row.fca }}
            </td>
          </tr>
        </tbody>
      </table>

      <button class="Upload_Button" v-if="!loading" @click="calculate">
        Calculate
      </button>
      <button class="Upload_Button_Loading" v-else>
        <img src="../../assets/LoadingButton.gif" />
      </button>
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
import { notify } from "@kyvg/vue3-notification";
import Calculate from "../../api/Eurbom/New_Calculation";

export default {
  name: "Calculate_Box",
  props: {
    load_again: Function,
  },
  data() {
    return {
      loading: false,
      token: "",
      selected_row: 0,
      factory: "MA",
      pref_year: null,
      pref_years: [],
      rows: [
        { id: 1, part_number: null, fca: null },
        { id: 2, part_number: null, fca: null },
        { id: 3, part_number: null, fca: null },
        { id: 4, part_number: null, fca: null },
        { id: 5, part_number: null, fca: null },
        { id: 6, part_number: null, fca: null },
        { id: 7, part_number: null, fca: null },
        { id: 8, part_number: null, fca: null },
        { id: 9, part_number: null, fca: null },
        { id: 10, part_number: null, fca: null },
      ],
    };
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  created() {
    this.token = localStorage.getItem("Token");
    this.pref_years = this.get_years_array();
  },

  mounted() {
    // Dodajemo event listener za paste
    document.addEventListener("paste", this.handlePaste);
  },
  beforeUnmount() {
    // Uklanjamo event listener za paste kada komponenta bude uništena
    document.removeEventListener("paste", this.handlePaste);
  },

  methods: {
    close() {
      if (!this.loading) this.$emit("close", "close");
    },

    get_years_array() {
      const currentYear = new Date().getFullYear();
      return [
        { id: 1, year: currentYear - 1, value: (currentYear - 1) % 100 },
        { id: 2, year: currentYear, value: currentYear % 100 },
        { id: 3, year: currentYear + 1, value: (currentYear + 1) % 100 },
      ];
    },

    async calculate() {
      let data = this.rows.filter(
        (item) =>
          item.part_number !== null && item.part_number.trim().length > 0
      );

      if (data.length == 0) return;

      if (this.pref_year == null) {
        notify({
          title: "Invalid preferential year !",
          type: "Error",
        });
        return;
      }

      data = data.map((item) => {
        item.factory = this.factory;
        item.pref_year = this.pref_year;
        return item;
      });

      if (
        data.some(
          (item) => item.fca === null || item.fca == 0 || isNaN(item.fca)
        )
      ) {
        notify({
          title: "Invalid FCA price !",
          type: "Error",
        });
        return;
      }

      data.forEach((item) => {
        // Ako je part_number string, pretvori sva slova u velika
        if (typeof item.part_number === "string") {
          item.part_number = item.part_number.toUpperCase();
        }
      });

      this.loading = true;

      // Pravimo niz svih Promise poziva, ali koristićemo async/await za čekanje na svaki odgovor
      for (let item of data) {
        try {
          const response = await Calculate.Calculate(
            item.part_number,
            item.fca,
            item.factory,
            item.pref_year,
            this.token
          );
          // Uspesan odgovor
          notify({
            title: `Calculation for ${item.part_number} successful !`,
            type: "Success",
          });
        } catch (error) {
          // Greška za konkretan poziv
          if (
            error.response &&
            error.response.data.message ===
              "A calculation with this product name already exists and is not archived."
          ) {
            notify({
              title: `Calculation for ${item.part_number} already exists !`,
              type: "Error",
            });
          } else {
            notify({
              title: `Error for ${item.part_number}`,
              message: error.message || "Unknown error",
              type: "Error",
            });
          }
        }
      }

      this.loading = false;
      this.load_again(); // Pozivamo load_again() nakon što su svi pozivi završeni
    },

    set_selected_row(index) {
      this.selected_row = index;
    },

    updateData(index, field, event) {
      this.rows[index][field] = event.target.innerText;
    },

    handlePaste(event, field) {
      const clipboardData = event.clipboardData.getData("text");
      const rows = clipboardData.split("\n"); // Razdvaja podatke po redovima (na osnovu novog reda)

      // Sprečavamo da se podaci zalepe u HTML formatu
      event.preventDefault();

      // Ispituje koliko imamo redova
      rows.forEach((row, rowIndex) => {
        const columns = row.split("\t"); // Razdvaja podatke po kolonama (tabulatorom)

        const targetIndex = this.selected_row + rowIndex;
        if (targetIndex >= this.rows.length) {
          return; // Ako indeks izlazi iz opsega, ignorišemo preostale podatke
        }

        // Ako kopiramo samo jednu kolonu, lepi u odgovarajući field
        if (columns.length == 1) {
          if (field == "part_number") {
            this.rows[this.selected_row + rowIndex].part_number =
              columns[0].trim();
          }
          if (field == "fca") {
            this.rows[this.selected_row + rowIndex].fca = columns[0].trim();
          }
        }

        // Ako ima dve kolone, lepi vrednosti u odgovarajuće kolone
        if (columns.length >= 2) {
          this.rows[this.selected_row + rowIndex].part_number =
            columns[0].trim();
          this.rows[this.selected_row + rowIndex].fca = columns[1].trim();
        }
      });
    },
  },
};
</script>

<style scoped>
.Calculate_Parrent {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  overflow: auto;
}

.Calculate {
  width: 500px;
  height: fit-content;
  background-color: white;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  border-radius: 10px;
  max-height: 80vh;
  overflow: auto;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.Title {
  font-size: 18px;
  font-weight: bold;
  font-family: Roboto;
}

.Container {
  width: calc(100% - 40px);
  height: 40px;
  background-color: #f2f6ff;
  margin-left: 20px;
  border: 1px dashed #839fec;
  margin-top: 10px;
}

.Row {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Row > input {
  height: 20px;
  margin: 5px;
  border: none;
  background-color: white;
  -webkit-box-shadow: 0px 0px 3px 1px rgb(236, 236, 236);
  -moz-box-shadow: 0px 0px 3px 1px rgb(236, 236, 236);
  box-shadow: 0px 0px 3px 1px rgb(236, 236, 236);
  outline: none;
  font-family: Roboto;
  font-size: 14px;
  width: 200px;
}

.Row > select {
  height: 22px;
  width: 204px;
  margin: 5px;
  border: none;
  background-color: white;
  -webkit-box-shadow: 0px 0px 3px 1px rgb(236, 236, 236);
  -moz-box-shadow: 0px 0px 3px 1px rgb(236, 236, 236);
  box-shadow: 0px 0px 3px 1px rgb(236, 236, 236);
  outline: none;
  font-family: Roboto;
  font-size: 14px;
}

.Upload_Button {
  background-color: #dae2e6;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 17px;
  cursor: pointer;
  align-self: flex-end;
  color: #494949;
  padding-top: 10px;
  padding-bottom: 10px;
  align-self: center;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Upload_Button:hover {
  background-color: #0288a7;
  color: white;
}

.Upload_Button:active {
  background-color: #0286a3;
  color: white;
}

.Upload_Button_Loading {
  background-color: #dae2e6;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 17px;
  cursor: pointer;
  align-self: flex-end;
  color: #494949;
  align-self: center;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Upload_Button_Loading > img {
  height: 30px;
  width: 30px;
  object-fit: cover;
  margin-top: 3px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Uklanja strelice u Firefox-u */
input[type="number"] {
  -moz-appearance: textfield;
}

.table {
  width: calc(100% - 40px);
  height: fit-content;
  background-color: #f2f6ff;
  margin-left: 20px;
  border: 1px dashed #839fec;
  margin-top: 10px;
  table-layout: fixed; /* Ova opcija sprečava promenu širine kolona prilikom unosa */
  border-collapse: collapse;
}

th {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

td {
  font-family: Roboto;
  font-size: 13px;
  border: 1px dashed grey;
  font-weight: 300;
  outline: none;
  text-align: center;
}

th:first-child,
td:first-child {
  width: 80%; /* Postavi širinu za prvu kolonu */
}
th:nth-child(2),
td:nth-child(2) {
  width: 20%; /* Postavi širinu za drugu kolonu */
}

td:first-child,
td:first-child {
  width: 80%; /* Postavi širinu za prvu kolonu */
}
td:nth-child(2),
td:nth-child(2) {
  width: 20%; /* Postavi širinu za drugu kolonu */
}
</style>
