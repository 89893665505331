import axios from "axios";

const url = `https://myexcel.app:3000/eurBoom/editBoomRows`;

class Edit_Bom {
  static Edit_Bom(eurBoomCalcId, fca, updates, token) {
    return axios.put(
      url,
      {
        eurBoomCalcId,
        fca,
        updates,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  }
}
export default Edit_Bom;
