import axios from "axios";

const url = "https://myexcel.app:3000/eurBoom/getCalculationPDF/";

class Download_Report {
  static async Download_Report(id, name, token) {
    try {
      const response = await axios.get(`${url}${id}/pdf`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      // Kreiraj URL od preuzetog PDF-a
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `Report_${name}.pdf`); // Postavi ime fajla
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Ukloni link nakon preuzimanja
    } catch (error) {
      console.error("Greška pri preuzimanju PDF-a:", error);
    }
  }
}

export default Download_Report;
