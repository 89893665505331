import axios from "axios";

const url = `https://myexcel.app:3000/eurBoom/getBoomListByCalcId`;

class Get_Bom_List {
  static Get_Bom_List(id, token) {
    return axios.get(`${url}?eurBoomCalcId=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
export default Get_Bom_List;
