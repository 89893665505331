import { createRouter, createWebHistory } from "vue-router";
import RequireService from "../components/EngineeringComponents/RequireService.vue";
import ToolsList from "../components/EngineeringComponents/ToolsList.vue";
import TerminalsList from "../components/EngineeringComponents/TerminalsList.vue";
import Reports from "../components/EngineeringComponents/Reports.vue";
import Machines from "../components/SparePartsComponents/Machines.vue";
import SpareParts from "../components/SparePartsComponents/SpareParts.vue";
import Management from "../components/SparePartsComponents/Management.vue";
import HistoryReports from "../components/SparePartsComponents/History.vue";
import ExportSchema from "../components/TechnologyComponents/ExportSchema.vue";
import EurBomReports from "../components/EurBomComponents/Reports.vue";
import EurBomArchive from "../components/EurBomComponents/Archive.vue";

const routes = [
  {
    path: "/",
    name: "LoginScreen",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "loginscreen" */ "../views/LoginView.vue"),
  },
  //  {
  //    path: '/engineering',
  //    name: 'engineering',
  //    children: [
  //      { path: "reqservice", component: RequireService },
  //      { path: "toolslist", component: ToolsList },
  //      { path: "terminalslist", component: TerminalsList },
  //      { path: "reports", component: Reports },
  //    ],
  //    // route level code-splitting
  //    // this generates a separate chunk (about.[hash].js) for this route
  //    // which is lazy-loaded when the route is visited.
  //    component: () => import(/* webpackChunkName: "engineering" */ '../views/EngineeringView.vue'),
  //  },
  //  {
  //    path: '/maintenance',
  //    name: 'maintenance',
  //    // route level code-splitting
  //    // this generates a separate chunk (about.[hash].js) for this route
  //    // which is lazy-loaded when the route is visited.
  //    component: () => import(/* webpackChunkName: "maintenance" */ '../views/MaintenanceView.vue'),
  //  },
  {
    path: "/spareparts",
    name: "spareparts",
    children: [
      { path: "machines", component: Machines },
      { path: "spareparts", component: SpareParts },
      { path: "management", component: Management },
      { path: "history", component: HistoryReports },
    ],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "spareparts" */ "../views/EngineeringView.vue"
      ),
  },

  {
    path: "/technology",
    name: "technology",
    children: [{ path: "exportschema", component: ExportSchema }],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "spareparts" */ "../views/EngineeringView.vue"
      ),
  },

  {
    path: "/eurbom",
    name: "eurbom",
    children: [
      { path: "reports", component: EurBomReports },
      { path: "archive", component: EurBomArchive },
    ],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "spareparts" */ "../views/EurBomView.vue"),
  },

  {
    path: "/settings",
    name: "settings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/SettingsView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
