import axios from "axios";

const url = `https://myexcel.app:3000/technology/deleteByProductNumber`;

class Delete_Scheme {
  static Delete_Scheme(id, token) {
    return axios.delete(`${url}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}
export default Delete_Scheme;
