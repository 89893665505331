import axios from "axios";

const url = `https://myexcel.app:3000/eurBoom/getAllCalculations`;

class Get_All_Calculations {
  static Get_All_Calculations(name, archive, page, preferential, token) {
    const params = new URLSearchParams({
      name,
      archive,
      page,
    });

    // Dodajemo preferential samo ako nije null ili undefined
    if (preferential != null) {
      params.append("preferential", preferential);
    }

    return axios.get(`${url}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default Get_All_Calculations;