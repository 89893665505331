<template><router-view />
<notifications position="top center" classes="my-notification" /></template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html,
body {
  margin: 0px !important;
  padding: 0px !important;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #302c3c;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.my-notification {
  margin: 0 5px 5px;
  padding: 10px;
  color: rgb(70, 70, 70);
  font-family: Roboto;
  border-radius: 4px;
  border-left: 3px solid rgb(55, 177, 30);
  margin-bottom: 10px;
  font-size: 14px;
  font-family: 'Open Sans';
  -webkit-box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.75);
  margin-top: 10px;

  &.Success {
    background: rgb(245, 245, 245);
    border-left-color: #17a840;
    color: rgb(65, 65, 65);
    font-family: Roboto;
  }

  &.warn {
    background: rgb(245, 245, 245);
    border-left-color: #f48a06;
    color: rgb(65, 65, 65);
    font-family: Roboto;
  }

  &.Error {
    background: rgb(245, 245, 245);
    border-left: 3px solid #f03546;
    color: rgb(65, 65, 65);
    font-family: Roboto;
  }
}
</style>
