<template>
    <div class="card">
        <div class="card__image"></div>
    </div>
</template>


<style scoped>
.card {
    width: calc(100% - 100px);
    height: 30px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(189, 189, 189, 1);
  box-shadow: 0px 0px 3px 0px rgba(189, 189, 189, 1);
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 50px;
    border-radius: 4px;
}

.card__image {
    background: linear-gradient(110deg, #f3f3f3 8%, #e9e9e9 18%, #f3f3f3 33%);
    border-radius: 2px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
    height: 30px;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
</style>