<template>
  <div class="Calculation_Details">
    <table class="Table">
      <tr style="text-align: center; background-color: #0097b8">
        <th class="th">Part Number</th>
        <th class="th">Item</th>
        <th class="th">Quantity</th>
        <th class="th">Price BAM</th>
        <th class="th">Price €</th>
        <th class="th">Total Price €</th>
        <th class="th">
          <select
            name="preferential"
            id="preferential"
            v-model="selected_preferential"
          >
            <option value="null" disabled>Preferential</option>
            <option
              v-for="(option, index) in options"
              :key="index"
              :value="option"
            >
              {{ option }}
            </option>
            <option value="Clear" style="color: #ffbfbf; font-weight: 400">
              Clear
            </option>
          </select>
        </th>
      </tr>
      <tr style="text-align: center" v-if="loading">
        <td colspan="7" class="td" style="border-bottom: none">Loading...</td>
      </tr>
      <tr
        style="text-align: center"
        v-for="item in result.filter(
          (i) =>
            !selected_preferential ||
            (selected_preferential === 'Blanks' &&
              (!i.prefYear || i.prefYear.trim() === '')) ||
            i.prefYear === selected_preferential
        )"
        :key="item.id"
        v-else
        :class="{ red_row: item.priceEur == 0 }"
      >
        <td>{{ item.nameProduct }}</td>
        <td>{{ item.nameComponent }}</td>
        <td>{{ item.quantity }}</td>
        <td>
          {{ item.priceBam }}
        </td>
        <td>{{ item.priceEur }}</td>
        <td>{{ (Math.round(item.priceEur * item.quantity * 10000) / 10000).toFixed(4) }}</td>
        <td>{{ item.prefYear }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import Get_Bom_List from "../../api/Eurbom/Get_Bom_List";

export default {
  name: "Calculation_Details",
  data() {
    return {
      loading: false,
      token: "",
      options: [],
      selected_preferential: null,
      result: [],
      updates: [],
    };
  },

  props: {
    data: Object,
  },

  watch: {
    result: {
      handler(newItems) {
        this.updates = newItems.filter(
          (item) => item.priceBam !== item.original_price
        );
      },
      deep: true,
    },
    selected_preferential(newValue) {
      if (newValue == "Clear") {
        this.selected_preferential = null;
      }
    },
  },

  created() {
    this.token = localStorage.getItem("Token");
    this.get_bom_list();
  },

  methods: {
    async get_bom_list() {
      this.loading = true;
      Get_Bom_List.Get_Bom_List(this.data.id, this.token)
        .then((res) => {
          let temp_result = res.data.data;
          for (let i = 0; i < temp_result.length; i++) {
            temp_result[i].original_price = temp_result[i].priceBam;
          }
          this.result = temp_result;
          this.options = res.data.data
            .map((item) =>
              item.prefYear && item.prefYear.trim() ? item.prefYear : "Blanks"
            )
            .filter((value, index, self) => self.indexOf(value) === index);
          this.loading = false;
        })
        .catch(() => {
          notify({
            title: err,
            type: "Error",
          });
          this.loading = false;
        });
    },

    handleInput(item) {
      if (item.priceBam === "" || item.priceBam === null) {
        item.priceBam = 0;
      }
    },

    selectText(event) {
      // Koristimo setTimeout da sačekamo da Vue ažurira vrednost inputa
      setTimeout(() => {
        event.target.select();
      }, 0);
    },

    getUpdates() {
      return this.updates; // Vraćamo updates kada se pozove
    },
  },
};
</script>

<style scoped>
.Calculation_Details {
  height: min-content;
  position: relative;
}

/* Za WebKit pretraživače (Chrome, Safari, Edge) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Za Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.Table {
  width: 800px;
  margin-top: 30px;
  margin-left: 20px;
  margin-bottom: 20px;
  border-collapse: collapse;
}

.th {
  font-family: Roboto;
  font-size: 13px;
  color: white;
  font-weight: 300;
}

td {
  font-family: Roboto;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 0.5px dashed #8f8f8f;
}

input {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
  background-color: white;
  border: none;
  outline: none;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(180, 180, 180, 0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(180, 180, 180, 0.75);
  box-shadow: 0px 0px 3px 0px rgba(180, 180, 180, 0.75);
  border-radius: 4px;
  width: 60px;
  text-align: center;
}

.red_row {
  color: red;
}

select {
  background-color: transparent;
  border: none;
  font-family: Roboto;
  font-size: 13px;
  color: white;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  width: fit-content;
}

select:hover {
  background-color: #0c9ebe;
}

option {
  background-color: #0097b8;
  font-family: Roboto;
  font-size: 13px;
}
</style>
